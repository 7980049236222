<template>

  <div class="pb ">
 
    <div class="headerow">

      <h1>
        {{ $t('growers_view_title') }} 
      </h1>

      <GeneralSearchShort
        @search="changeKeyword"
        :val="keyword"
        :placeholder="$t('growers_search_placeholder')"
        />  

      <UiSort 
        @choose="sort = $event"
        :val="'diaries'"    
        :options="[
          {id:'diaries', name: $t('universal_sort_diaries')},    
          {id:'likes', name: $t('universal_sort_likes2')},        
          {id:'reg', name: $t('universal_sort_signup_date')},      
          {id:'visit', name: $t('universal_sort_last_visited')},      
        ]"
        />
      
    </div>
 

    <div 
      v-if="usersData?.items.length"
      class="growers_boxs results two_column">
      <GeneralUser 
        v-for="(opt,ind) in usersData.items"
        :key="ind"  
        :num="ind+1"  
        :data="opt"      
      />

      <ClientOnly>
        <UiInfinityScroll 
          :is-loading="usersDataStatus === 'pending'"
          ref="elInfinityScroll"/>
      </ClientOnly>
    </div>

    <SkGrowerRows
      v-else-if="usersDataStatus === 'pending' && !usersData?.items.length"
      />

    <UiEmpty
      v-else
      />


  

  </div>
</template>



<script setup lang="ts">

import type { User }  from "@/types/user"

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'


const start = ref<number>(0)
const limit = ref<number>(20)
const isEnded = ref<boolean>(false)
const {$api, $adv} = useNuxtApp()
const elInfinityScroll = ref(null)
const sort = ref<string>('diaries')
const keyword = ref<string>('')


const changeKeyword = function(text: string){  
  start.value = 0;
  keyword.value = text;
}  

const loadData = async function() {     
  const response = await $api.getGrowers({
    keyword: keyword.value,
    sortable: sort.value,
    start: start.value,
    limit: limit.value,
  })  
  return response;  
}


interface UsersPage {
  items: User[]
}


const { status: usersDataStatus,  data: usersData } = await useLazyAsyncData('usersData', async () => await loadData() as UsersPage, {deep: true})



watchArray([start, sort, keyword], async ([newStart, newSort, newKeyword], added, removed) => {
  console.log('watcher');

  if(newStart == 0){
    usersData.value.items = [];
    isEnded.value = false;
  }
  
  usersDataStatus.value = 'pending';

  var dt = await loadData();

      
  if(dt?.items.length == 0)
    isEnded.value = true;
  
  usersData.value.items = [...usersData.value.items, ...dt.items]  

  usersDataStatus.value = 'success';

  $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(usersDataStatus.value === 'success' && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

useHead(useNuxtApp().$head.getGrowerList());


</script>

<style scoped>

h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
}



@media (min-width: 1100px) {
  .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  
}

@media (min-width: 1350px) {
  
}

/* WITH SIDEBAR */


@media (min-width: 1100px) {
  .onnav .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  } 
}
 


@media (max-width: 768px) {
  .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  } 
}
 

</style>
